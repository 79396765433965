.navpan {
  margin-bottom: 0px;
  min-height:initial;
  border: none;
  padding-top:80px;
  background-color:  #d8e1e7;
    position: relative;
    height: 100vh;
}
 
.navpan ul li a {
  color: #fff;
  font-size:  14px;
  padding: 7px 15px 7px 20px;
    font-family: 'gt_walsheimMedium';
}

/*.navpan ul li a:focus, .navpan ul li a:hover, .navpan ul li a:active{
  background-color: #3399ff;
}*/


.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
  outline:none;
}

.navpan .nav>li>a:focus, .navpan .nav>li>a:hover {
    text-decoration: none;

}

.navpan .nav-pills>li>a {
    border-radius: 0px;
}

.parkableLogoBlue {
    position: absolute;
    top: 40px;
    left: 18px;
    width: 180px;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
    color: #fff;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover{
  background-color: transparent;
}

.nav>li>a{
  padding:5px 0px;

}

.logout-button {
    position: fixed!important;
    bottom: 100px;
    max-width:300px;
    width: 100%;
    padding: 20px;
}
.logout-button:hover {

    /* text-align: center; */
    width:100%;
    padding: 20px!important;
}
.logout-button a {
    border: 1px solid #1F2034;
    border-radius: 50px!important;
    display: inline-block;
    width: 85%;
    padding: 7px 0;
    text-align: center;
}

/*.logout-button a:hover {*/
/*    border: 1px solid #3399ff!important;*/
/*    border-radius: 50px!important;*/
/*    background: #294f6b!important;*/
/*}*/

.nav>li>a:focus, .nav>li>a:hover{
  background-color: transparent;
  outline: none;
}