
#content {
    background-color:#D8E1E7;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    padding: 20px;
    max-width: 900px;
    overflow-y: auto; 
    max-height:900px;
  }