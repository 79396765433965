@media all and (min-width: 960px) {
  .oh-no{
    position: absolute;
    top: 100px;
    left: 75px;
    color:  #f14950;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 90px;
    font-weight: bold;
  }

  .not-found{
    position: absolute;
    top: 220px;
    left: 75px;
    color:  #666666;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 32px;
  }

  .error-code{
    position: absolute;
    top: 350px;
    left: 75px;
    color:  #666666;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: regular;
  }

  .start-again{
    position: absolute;
    top: 400px;
    left: 75px;
    color:  #2196f3;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: regular;
    border: 0px;
    background: transparent;
    padding: 0px;
  }

}

@media all and (max-width: 959px) and (min-width: 650px) {
  .oh-no{
    position: absolute;
    top: 100px;
    left: 50px;
    color:  #f14950;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 48px;
    font-weight: bold;
  }

  .not-found{
    position: absolute;
    top: 175px;
    left: 50px;
    color:  #666666;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
  }

  .error-code{
    position: absolute;
    top: 250px;
    left: 50px;
    color:  #666666;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: regular;
  }

  .start-again{
    position: absolute;
    top: 285px;
    left: 50px;
    color:  #2196f3;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: regular;
    border: 0px;
    background: transparent;
    padding: 0px;
  }
}


@media all and (max-width: 650px) and (min-width: 50px) {
  .oh-no{
    color:  #f14950;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 2vh;
  }

  .not-found{
    color:  #666666;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 2vh;
    font-weight: regular;
  }

  .error-code{
    color:  #666666;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1vh;
    font-weight: regular;
  }

  .start-again{
    color:  #2196f3;
    font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.5vh;
    font-weight: regular;
    border: 0px;
    background: transparent;
    padding: 0px;
  }
  img.sm-max-width{
    max-width: 300px;
  }
}