
div.App {
  background-color: #103b5d;
  min-height:100vh;
  display:flex;
  flex-direction: column;
}

div.mainPanel {
  flex:1
}

h1 {
  color: #fff;
}

h1.matterhorn {
  color: #555555;
}
label input[type="radio"] { vertical-align: text-bottom; margin: -11px 0 0;}
.container{
  width: 100% !important  ;
}

.admin-nav-panel {
  padding-left:0;
  padding-right: 0;
  min-height: 100vh;
}

.logout-link {
  position:absolute;
  top: 20px; right: 40px;
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
}
#footer {
  padding: 30px 25px 25px;
  display: flex; /* or inline-flex */
  flex-direction: row;
  justify-content: space-between;
  background: #333;
  width: 100%;
}
#footer img{
  margin-right: 10px;
}
#footer .footer-logo {
  color: #C3CDD6;
  font-weight: 300;
  margin: 0 0;
}

#footer .terms-conditions a, .terms-conditions span {
  font-weight: 300;
  font-size: 12px;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  background-color:  #d8e1e7;
  width: 100vw;
}

.wrapper-div{
  max-width: 1200px;
}
button.big-green {
  color: white;
  background-color: #7ac943;
  box-sizing: border-box;
  padding: 10px 30px 11px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
button.big-green:hover {
  color:#fff;
  background-color:#7ac943;
  border:none;
}
.error {
  border-color: #c62828
}