@font-face {
  font-family: 'gt_walsheimbold';
  src: url('./resources/fonts/gt-walsheim-bold-webfont.woff2') format('woff2'),
  url('./resources/fonts/gt-walsheim-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_walsheimregular';
  src: url('./resources/fonts/gt-walsheim-regular-webfont.woff2') format('woff2'),
  url('./resources/fonts/gt-walsheim-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_walsheimmedium';
  src: url('./resources/fonts/gt-walsheim-medium-webfont.woff2') format('woff2'),
  url('./resources/fonts/gt-walsheim-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  min-height: 100vh;
  background-color:  #d8e1e7;
}

#root {
  min-height: 100%;
}

#allbutfooter {
  min-height: calc(100vh - 170px);  
  padding-bottom: 60px;
}



h1 {
  color: #1a237e;
  font-weight: 700;
  font-family: 'gt_walsheimbold',"Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h2 {
  color: #555555;
  font-weight: bold;
  font-family: 'gt_walsheimbold', "Source Sans Pro",Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 30px;
  margin-top: 30px;
}

h3 {
  font-weight: bold;
  font-family: 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}

h4 {
    color: #555555; 
    font-weight: bold;
    font-size: 15px;
    font-family: 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
a {
  color: #3399ff;
  font-weight: 600;
  text-decoration: none;
}

a:hover{
  color: #3399ff;
  outline:none;
  cursor: pointer;
  text-decoration: none;
}
th {
  font-weight:bold!important;
}


.parkableGrey {
  color: #555555;
}

.parkableBlue {
  color: #3399ff;
}

.darkBlue {
  background-color: #0f3b5d;
}

label {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #555555;
}

input {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -moz-border-radius: 4px; /* Firefox */
  -webkit-border-radius: 4px; /* Safari, Chrome */
  -khtml-border-radius: 4px; /* KHTML */
  border-radius: 4px; /* CSS3 */
  border-style: solid;
  border-width: 1px;
  height: 45px;
  color: #222222;
  font-weight: 400;
  border-color: #c9c9c9;
  padding-left: 16px;
  outline:none;
}

select {
  color: #3399ff;
  background-color: white;
  font-weight: bold;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px; 
  width: 100%;
  border-radius: 10px;
  height: 40px;
  outline:none;
  border-color: rgb(226, 226, 226)
}

button.standard-green, button.standard-green:hover, button.standard-green:focus{
  background-color: #7ac943;
  color: white;
  font-family: 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 6px;
  border: none;
  padding: 10px 30px;
}
button.standard-green:hover{
 box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.1);
}
button.standard-green:disabled{
  background-color: #3399ff; /*needs a good green colour*/
}

button.small-green, button.small-green:hover, button.small-green:focus{
  background-color: #7ac943;
  color: white;
  font-family: 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  border-radius: 7px;
  border: none;
  padding: 10px 45px;
  margin:0px 5px 0px 0px;
}
button.small-green:hover{
 box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.1);
}

button.standard, button.standard:hover, button.standard:focus{
  background-color: #3399ff;
  color: white;
  font-family: 'gt_walsheimbold',"Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  padding: 6px 40px 6px 40px;

}

button.standardSmall {
  background-color: #3399ff;
  color: white;
  font-family: 'gt_walsheimbold',"Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  border-radius: 3px;
  border: none;
  padding: 8px 18px;
  margin: 0 5px;
}
button.standardSmallWhite {
  background-color: #fff;
  color: #3399ff;
  border: #3399ff 1px solid;
}

button.groups {
  background-color: transparent;
  color: #3399ff;
  font-weight: bold;
  font-size: 16px;
  padding: 2px 5px;
  border: 0px transparent;
}

button.big-green {
  background-color: #7ac943;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 12px 20px;
  border: 0px transparent;
  border-radius: 5px;
}


.spinner-container{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
}

.spinner-box{
  position: relative;
  margin:auto;
  top:45%;
  width: 100px;
  height: 100px;
}

.spinner-small{
  width: 20px;
  height: 20px;
}




.btn-default:active:focus, .btn-default:active:hover, button:focus{
  outline: none!important;
}

.navpan{
  padding-left: 20px;
}



.navpan img{
  margin-left: 20px;
}


.filterName.panel-default{
  border-radius: 5px!important;
}

.panel-default{
  border:none;
  border-radius:0px;
  -webkit-box-shadow: 0px 0px 4.65px 0px rgba(0,0,0,0.15);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  margin-bottom: 0px;
  padding: 0px 40px;

}

.badge{
 padding: 2px 6px 3px 6px;
 border-radius: 40px;
}

.navbar2 .nav-pills>li{
  float: none!important;
  display: inline-block;

}
div#user-tabs-pane-sessions > div{
  padding-left: 0px;
  padding-right: 0px;
}

.btn-show-menu{
  display: none;
  color: #555555;
}

@media (max-width: 767px){
  .panel {
    margin:10px 5px;
  }

  .admin-nav-panel{
    z-index: 999;
    float: left;
    position: absolute;
    opacity: 0.92;
    width: 60%;
  }

  .btn-show-menu{
      display: inline !Important;
  }

}



div.no-border-radius .panel{
  border-radius:0px;
}

.page-header {
  border-bottom: transparent;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

th {
  font: 15px "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #555555;
}

.table>thead>tr>th{
  border-bottom: 1px solid #f2f2f2;
}

.table>tbody>tr{
  border-bottom: 1px solid #f2f2f2;
}

tfoot{
 border-bottom: 1px solid #f2f2f2;
}

.table>tbody {
  font: normal 13px "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.table>tbody>tr>td {
 border-bottom: 1px solid #f2f2f2;

}

.table-footer {
  font-weight: bold;
  color: #555555;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #888888;
  border-bottom: 1px solid #888888;
}

td {
  font: 13px "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #555555;
  text-align: left;  
}

.userSummary {
  color: #fff;
  background-color: #103b5d;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: none;
}

hr.noBorder {
  margin-bottom: 0px;
  border: 0;
}

div.plaintext {
  font-size: 14px;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #555555;
}

.panel-default > .panel-heading {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #555555;
  background-color: #fff;
  border-width: 0px;
  border-bottom: 1px solid #e1e1e1;
  padding:30px;
  text-align: left;

}


.nav-tabs>li:nth-child(1){
  margin-left: 10px;
}

button.submit {
  background-color: red;
  font: 14px bold 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;  
  font-family: 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
  background-color: #3399ff;
  border-radius: 5px;
  border: 0px;
  padding: 5px 15px 5px 15px;
}

button.link {
  background-color: transparent;
  color: #3399ff;
  font: 15px 700 "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family:"Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 0px;
}

button.blank {
  background-color: transparent;
  color: #3399ff;
  font: 13px bold 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: 'gt_walsheimbold', "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 0px;
  text-decoration: none;
}

div.react-datepicker {
  zoom: 1.3;
  -moz-transform: scale(1.3);
  font-weight: normal;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #555555;
}

.react-datepicker__input-container {
  width: 100%;
  outline: none;
}

.react-datepicker__input-container input{
  width:100%;
  outline: none;
  padding:5px;
  border-radius:6px;
  border-color: #e2e2e2;
}

.react-datepicker-ignore-onclickoutside {
  width: 100%;
  outline: none;
}

.react-datepicker__portal{
  background-color: rgba(0, 0, 0, 0.3);
}

.wide100-input > input {
  width: 100%;
}

.pagination > li > a, .pagination > li > span {
  color: #2196f3;
  font-size: 15px;
  font-weight: bold;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #3399ff;
  border-color: #3399ff;
}

.panel-group .panel {
  -webkit-border-radius:0;
  -moz-border-radius:0;
  border-radius:0;
}
.panel-group .panel+.panel {
  margin-top: 0;
}

/*div.pagination_translate {*/
  /*transform: translateX(50%);*/
/*}*/

/*ul.pagination {*/
 /*transform: translateX(-50%); */
/*}*/

div.table_head {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: #555555;
  margin-top: 20px;
  border-top: solid 1px #CCC;
  border-bottom: solid 1px #CCC;
  line-height: 2.2;
}

div.table_row {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #555555;
}

div.photo_gallery{
  margin:0px -15px -15px -15px;
  background-color: rgba(209, 211, 212, 0.1);
  padding: 10px 20px 20px 20px;
  border-top: 1px solid #f2f2f2; 
}

div.grid_in_panel {
  width: 100%;
  padding: 0px;
}


div.App {
  background-color: #f2f2f2;
  min-height: 100%;
}

body {
  background-color: #f2f2f2;
}

div.dashboard {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #555555;
}


.loginForm {
  width: 400px;
  margin: 100px auto 0px auto;
}

/* the tree node's style */
.tree-view {
  overflow-y: hidden;
}

.tree-view_item {
  /* immediate child of .tree-view, for styling convenience */
}

/* style for the children nodes container */
.tree-view_children {
  margin-left: 16px;
}

.tree-view_children-collapsed {
  height: 0px;
}

.tree-view_arrow {
  cursor: pointer;
  margin-right: 6px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tree-view_arrow:after {
  content: '▾';
}

/* rotate the triangle to close it */
.tree-view_arrow-collapsed {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

img.center {
  display: block;
  margin: 0 auto;
  width: 400px;
}

@media screen and (max-width: 480px) {
  .loginForm {  
    width: 100%;
    margin: 100px auto 0px auto;
  }

  img.center {
    width: 250px;
  }
}

div.loginBox {
  background-color: #fff;
  padding: 30px 30px 30px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
}

button.center {
  display: block;
  margin: auto;
  padding: 6px 40px 6px 40px;
}

div.center {
  display: block;
  margin: auto;  
}

div.center-text {
  text-align: center;
}

h3.login {
  color: #555555;
  margin-top: 30px;
}

input.login {
  width: 90%;
}

div.loginfailed {
  font-size: 14px;
  font-weight: bold;
  color: #555555;
  text-align: center;
}

button.login-link {
  text-decoration: none;
  margin-top: 5px;
}

img.my-gallery {
  width: 180px;
  margin: 0px 20px 20px 0px;
}


#pfb-container-controlled-tabs-right{
  margin-top: -20px;
}
#user-tabs-pane-sessions .table_row > div{
  padding: 0px 2px;
}

@media (max-width: 420px){

  img.my-gallery {
    width:100px;
    display: inline-block;
    margin-right:5px;
    margin-bottom: 5px;
  }

}

div.light-underline:after {
  content: "";
  background: #e1e1e1;
  position: relative;
  left: 1%;
  height: 1px;
  width: 98%;

}

div.light-overline:before {
  content: "";
  background: #eee;  
  position: relative;
  left: 16px;
  height: 1px;
  width: 97%;
  margin-bottom: 2px;
}

div.my-row {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  color: #555555;
  height: 40px;
  margin-right: 0px; 
}

div.blue-outline {
  background-color: transparent;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  border-color: #2196f3;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

div.no-parks {
  background-color: #fff;
  padding: 30px 0px;
}

ul.green-tick {
  list-style-image: url('./resources/images/green_tick_circle.png');
}

li {
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #555555;
}

a.footer {
  display: inline-block;
  color: #3399ff;
}


div.clear {
  clear: both;
}

div.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

div.my-table {
  width: 100%;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  color: #555555;
}

div.tbl {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (min-width: 576px) {
  div.tbl-optional {
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    display: inline-block;
  }
}

div.tbl-top {
  border-top: solid 1px #e8e8e8;
}

div.tbl-top-dark {
  border-top: solid 1px #555555;
}

div.tbl-bottom {
  border-bottom: solid 1px #e8e8e8;
}

div.tbl-bottom-pad {
  padding-bottom: 20px;
}

div.tbl-bottom-dark {
  border-bottom: solid 1px #555555;
}

div.tbl-right {
  border-right: solid 1px #e8e8e8;
}

@media screen and (min-width: 576px) {
  div.tbl-right-optional {
    border-right: solid 1px #e8e8e8;
  }
}

div.tbl-head {
  font-size: 16px;
}

div.no-pad .panel-body {
  padding: 0px;
}

div.white {
  background-color: transparent;
}

div.no-gst {
  background-color: white;
  padding: 10px 0px;
  margin-bottom: 10px;
}

div.gst-title {
  margin-bottom: 10px;
  margin-top: 30px;
  color:#555555;
}

div.gst-group {
padding:20px 20px 30px 20px;
margin-bottom: 20px;
background-color: #fff  
}


.filterName{ 
  color:#888888;
  font-weight:bold;
  font-family:"Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size:18px;
}
.filterName label {
  font-size: 14px;
}
.filter-buttons {
  padding: 15px 0 0;
}
.filter-buttons button {
  font-size: 16px;
  border-radius: 5px;
  border: none;
  letter-spacing: 0.1px;
  font-weight: 900;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

div.table_head{
  border-top: solid 1px #f2f2f2;
  border-bottom: solid 1px #f2f2f2;
  padding-top:5px;
  padding-bottom:5px;
  margin-bottom: 7px;
  font-color: #555555;
}

div.table_row{
  color:#888888;
  padding-bottom: 6px;
  border-bottom: 1px solid #f2f2f2; 
}


.imgremove {
  position: absolute;
  bottom: 0;
  right: 0;
}

div.wordwrap { 
   white-space: pre-wrap;      /* CSS3 */   
   white-space: -moz-pre-wrap; /* Firefox */   
   white-space: -o-pre-wrap;   /* Opera 7 */    
   word-wrap: break-word;      /* IE */
}

@media screen and (max-width: 767px) {
  .table>tbody>tr>td.double-row-first {
    border-bottom-color: transparent;
  }
}

div.table-label {
  font-size: 12px;
  margin-top: 10px;
  color: #888888;
  font-weight: bold;
}
div.table-item {
  color: #555;
}

div.green-dot {
  display: inline-block;
  background-color: #7ac943;
  height: 8px; 
  width: 8px;
  border-radius: 4px;
}

h3.park-active {
  display: inline-block; 
  margin-left: 10px;
  font: bold 16px "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height:20px!important;
}

.form-control {

  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight:500;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  box-shadow: none;
}

.has-error .form-control {
  border-color: #f14950;
}

div.gst-title-bar{
-webkit-box-shadow: 0px 0px 4.65px 0px rgba(0,0,0,0.15);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
padding:20px 15px 10px 15px;
background-color:#f8f8f8;
border-bottom:1px solid #f2f2f2;
}

div.gst-title-bar-1{
border-top: 1px solid #e1e1e1;
padding: 20px 15px 10px 15px;

margin: -20px -20px 0px -20px;
}

.lease-button{
  margin:5px 0px!important;
  width: 100%;
}

div.lease-header {
  background-color: #0f3b5d; 
  color: white;
  font-weight: bold;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  padding: 18px 15px;
}
.lease-row-container {
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  margin-bottom: 20px;
}
.lease-row {
  padding: 15px;
  border-bottom: 1px solid #e1e1e1;
}
.lease-row img{
  margin-right: 15px;
}
.inline {
  display: inline-block;
}
.button-cancel-lease {
  padding: 20px;
  text-align: right;
}
.button-cancel-lease a {
  color: #888;
  cursor: pointer;
}


.availablity-edit-view{
      
      margin-bottom: 20px;

}

.nav-tabs{
  background-color: #f8f8f8;

}


@media screen and (min-width: 1200px){

.park-overview{
  width:100%;
}

}




@media screen and (max-width: 992px){

.create-park-button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-right: 15px;
}

.create-park{
  padding-right: 30px;
}



}

button.create-park-button, button.create-park-button:hover, button.create-park-button:focus{
  background-color: #7ac943;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  letter-spacing: .2px;
  font-weight: 900;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}



div.yellow-circle {
  width: 36px; 
  height: 36px; 
  background-color: #fbb040; 
  color: #0f3b5d;
  border-radius: 18px;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 36px;
}

img.yellow-circle-icon {
  width: 36px; 
  height: 36px; 
  background-color: #fbb040; 
  border-radius: 18px;
  padding: 3px 3px 3px 3px;
  vertical-align: top;
}


img.yellow-circle-icon
div.text-error {
  color: red;
}




div.start-here {
  width: 100%;
  height: 250px;
  padding-top: 80px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
}

img.pad-right {
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.vertical-alignment-helper {
  display:table;
  height: 100%;
  width: 100%;
  pointer-events:none;
}
.vertical-align-center {
  /* To center vertically */
  display: table-cell;
  vertical-align: middle;
  pointer-events:none;
}
.modal-content {
  /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
  width:inherit;
  height:inherit;
  /* To center horizontally */
  margin: 0 auto;
  pointer-events:all;
}


span.available-plate {
  color: #7AC943;
  background-color: #E4FFD1;
  text-align: center;
  padding: 5px 10px;
}

span.suspended-box {
  background: #FE9EA2;

  color: #FFFFFF;
  font-weight: 600;
  text-align: center;
  padding: 5px 10px;
}

input[type=checkbox]{
    width: 16px;
    height: 16px;
    float: left;
    margin-right: 5px;
    margin-top: 2px;
}


select.sort-bays {
  color: rgb(85, 85, 85);
  background-color: white;
  font-weight: normal;
  font-family: "Source Sans Pro", Source Sans Pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 200px;
  border-radius: 5px;
  height: 40px;
}

button.test-options {
  color: #3399ff;
  background-color: transparent;
  border: transparent;
}

div.modal-fade {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.3;
  border: transparent 0px;
}

button.back {
  background: transparent;
  border: none;
  padding: 10px 0px 10px 0px;
}

button.simple-blue {
  background: transparent;
  border: none;
  color: #2196f3;
  margin-right: 3px;
}

button.simple-grey {
  background: transparent;
  border: none;
  color: #555555;
  margin-right: 3px;
}

button.simple-red {
  background: transparent;
  border: none;
  color: red;
  margin-right: 3px;
}

.account-profile label, .account-payment label {
  display: inline-block;
  margin-right: 10px;
}


.account-profile .form-group {
  display: inline-block;
  width: 89%;
}

.account-profile .form-control {
  width: 74%;
  display: inline-block;
}

.account-profile .form-group {
  display: inline-block;
  width: 89%;
}

.account-profile .react-tel-input {
  width: 74%;
  display: inline-block;
}

.account-profile a {
  display: inline-block;
}

.cleave {
  width: 100%;
  display: block;
  height: 50px;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-radius: 3px;
  box-shadow: none;
  -webkit-transition: none;
  transition: none;
}

.cleave:hover {
  border-color: #5fce17;
  cursor: pointer;
}

.inputClickable[disabled]:hover {
  border-color: #5fce17;
  cursor: pointer;
}

.inputDisabled[disabled] {
  color: #555;
  background-color: #eee;
}

.inputDisabled[disabled]:hover {
  border-color: #cccccc;
  cursor: not-allowed;
}

.cleave:focus {
  cursor: text;
  border: #cccccc 1px solid;
  outline: 0;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 0 0 5px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 1px 1px 5px rgba(0, 0, 0, 0);
  border-radius: 5px;
}

.cleave input::-webkit-input-placeholder {
  color: #888888;
}

.cleave input::-moz-placeholder {
  color: #888888;
}

.cleave input:-ms-input-placeholder {
  color: #888888;
}

.cleave input:-moz-placeholder {
  color: #888888;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


@media screen and (max-width: 480px){
  div.no-parks {
    padding: 10px;
  }
  .panel {
    margin:0px;
  }

}